import identifying from '../../../images/MedicalAppDevelopmentServices/ProcessOfDev/identifying.png'
import prototyping from '../../../images/MedicalAppDevelopmentServices/ProcessOfDev/prototyping.png'
import MVP from '../../../images/MedicalAppDevelopmentServices/ProcessOfDev/MVP.png'
import collecting from '../../../images/MedicalAppDevelopmentServices/ProcessOfDev/collecting.png'


const dataProcessOfDev = [
  {
    id: '1',
    image: identifying,
    title: 'Identifying Problems, \n Defining Solutions',
    textVisible: 'Together with you, we define the business tasks that need to be solved using the application. We determine over scenarios of user actions, highlight the highest priority functions, define the scope of work and draw up a Road Map for your application. And after that our team will help you to correctly describe the requirements for the functionality into the specifications.',
    textHidden: 'As a result, we will have a complete and clear understanding of what needs to be done, in what order and how it needs to be implemented so that your medical application is in demand by patients/doctors and brings you the desired result.',
  },
  {
    id: '2',
    image: prototyping,
    title: 'Prototyping (UX), \n Design Creation (UI)',
    textVisible: 'Based on the requirements for the app functionality, our designers will develop wireframes for the application, paying attention to the prototypes being intuitive and user-friendly for the end user. After you approve the interface mockups, our specialists will start designing it.',
    textHidden: 'The healthcare applications requires a special approach to the colors used to give your clinic / hospital patients a sense of care and safety. We will help you create a convenient and memorable design that will match your corporate style.',
  },
  {
    id: '3',
    image: MVP,
    title: 'MVP Development, \n Publication on App Stores',
    textVisible: 'The creation of the pilot version allows us to bring the application to the market in just a few months after the start of development. Also, with the MVP development, we will help you register accounts on the Play Market and App Store and correctly publish the application.',
    textHidden: 'It is important that medical software complies with healthcare software requirements for data protection and security. Therefore, we use technological solutions that meet the HIPAA software requirements.',
  },
  {
    id: '4',
    image: collecting,
    title: 'collecting Feedback, \n Upgrades & Functionality \n Enhancement',
    textVisible: 'When the first customers start using the hospital app, the first reviews will start coming in. After collecting a sufficient amount of opinions and information about user behavior in the application, we will analyze this data and prepare recommendations on how to improve the application.',
    textHidden: 'Along with this, we will add new functionality according to the Road Map until the project is completed. Our mission is to provide you with a result in the form of a medtech application that will appeal to your customers and take your business to a new level of development and profit.',
  },
];

export default dataProcessOfDev;