
const dataEngagementModels = [
    {
        id: '1',
        title: 'Fixed \n Price',
        textButton: 'Create a Product',
        description: [
            {
                question: 'Duration',
                answer: '2 - 3 months',
            },
            {
                question: 'Budget',
                answer: 'Predefined',
            },
            {
                question: 'Requirements',
                answer: 'Predefined',
            },
            {
                question: 'Methodology',
                answer: 'Waterfall',
            },
            {
                question: 'Client\'s Involvement',
                answer: 'Minimal',
            },
            {
                question: 'Flexibility',
                answer: 'Low',
            },
            {
                question: 'Best for',
                answer: 'Small & medium project',
            },
        ],
    },
    {
        id: '2',
        title: 'Dedicated \n Team',
        textButton: 'Build a Team',
        description: [
            {
                question: 'Duration',
                answer: 'Over 6 months',
            },
            {
                question: 'Budget',
                answer: 'Estimated',
            },
            {
                question: 'Requirements',
                answer: 'Evolving',
            },
            {
                question: 'Methodology',
                answer: 'Agile',
            },
            {
                question: 'Client\'s Involvement',
                answer: 'Constant',
            },
            {
                question: 'Flexibility',
                answer: 'Medium / High',
            },
            {
                question: 'Best for',
                answer: 'Large Project',
            },
        ],
    },
    {
        id: '3',
        title: 'Time & \n Material',
        textButton: 'Get a Specialist',
        description: [
            {
                question: 'Duration',
                answer: 'On Demand',
            },
            {
                question: 'Budget',
                answer: 'Not set',
            },
            {
                question: 'Requirements',
                answer: 'Not set',
            },
            {
                question: 'Methodology',
                answer: 'Agile',
            },
            {
                question: 'Client\'s Involvement',
                answer: 'Regular',
            },
            {
                question: 'Flexibility',
                answer: 'High',
            },
            {
                question: 'Best for',
                answer: 'Medium & Large Project',
            },
        ],
    },
];

export default dataEngagementModels;