import React, {useState} from 'react';
import './LookingDevelopers.scss'
import ModalPopUp from "../ModalPopUp";

export default function LookingDevelopers() {

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <div className="looking-dev-container">
        <div className="looking-dev-wrapper">
          <h2 className="looking-dev-title">
            Looking for healthcare app developers?
          </h2>
          <div className="looking-dev-text">
            Book a free 1-hour call with our app development consultant, business analyst, and software architect with
            no
            commitments
          </div>
          <button
            className="looking-dev-button"
            onClick={handleOpen}
          >
            to get a consultation
          </button>
        </div>
      </div>
      {
        openModal &&
        <ModalPopUp
          openModal={openModal}
          handleClose={handleClose}
          select={'Get a consultation'}
        />
      }
    </>
  );
}
