import React, {useState} from 'react';
import ModalPopUp from "../ModalPopUp";

export default function Card({
                               title,
                               description,
                               textButton
                             }) {

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <div className="em-card">
        <div className="em-card-body">
          <div className="em-card-title">{title}</div>
          <div className="em-card-content">
            <ul className="em-card-list">
              {
                description.map(item =>
                  <li key={item.question}>
                    <div className="em-card-list-question">{item.question}</div>
                    <div className="em-card-list-answer">{item.answer}</div>
                  </li>
                )
              }
            </ul>
            <button
              className="em-card-button"
              onClick={handleOpen}
            >
              {textButton}
            </button>
          </div>
        </div>
      </div>
      {
        openModal &&
        <ModalPopUp
          openModal={openModal}
          handleClose={handleClose}
          select={textButton}
        />
      }
    </>

  );
}
