import React, {useState} from 'react';

export default function FaqItem({
                                  id,
                                  number,
                                  question,
                                  answer
                                }) {

  const [activeItem, setActiveItem] = useState(false);

  const handleClick = () => {
    activeItem
      ? setActiveItem(false)
      : setActiveItem(true)
  }

  return (
    <li className="faq-list-item">
      <div className="faq-list-item-number">{number}</div>
      <div>
        <h3 className={`faq-list-item-question ${activeItem ? 'active' : ''}`}
            onClick={() => handleClick(id)}
        >
          {question}
        </h3>
        <div className={`faq-list-item-answer ${activeItem ? 'show' : ''}`}>
          {answer}
        </div>
      </div>
    </li>
  );
}
