const dataWhyChoseUs = [
  {
    id: '01',
    title: 'Understanding the specifics of the medical field',
    text: 'To develop software for the healthcare sector, it is important to have an understanding of the subject area and knowledge of the legal framework. To guarantee our clients a decent result, we undertake only those projects in which we have relevant experience and full understanding of how to implement them.',
  },
  {
    id: '02',
    title: 'Transparent communication',
    text: 'For us, transparency means permanent and direct communication between a customer and a team of developers leading to a clear distribution of responsibilities and a better understanding of the work progress. Our customers always know what the status of the project is and what the money is paid for.',
  },
  {
    id: '03',
    title: 'Integrity and Honesty',
    text: 'Our Data Protection Policy guarantees a high standard of compliance culture followed by each and every employee of OS-system. We ensure the highest degree of confidentiality and protection of our clients’ intellectual properties, contracts, strategies, trade secrets, and personal information.',
  },
];

export default dataWhyChoseUs;