import React from 'react';
import './WhyChoseUs.scss';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataWhyChoseUs';


export default function WhyChoseUs() {

  const ItemsArray = data.map(item =>
    <li className="wcu-list-item" key={item.id}>
      <div className="wcu-list-item-number">{item.id}</div>
      <div>
        <h3 className="wcu-list-item-title">{item.title}</h3>
        <div className="wcu-list-item-text">
          {item.text}
        </div>
      </div>
    </li>
  );

  return (
    <div className="wcu-container">
      <div className="wcu-wrapper">
        <h2 className="wcu-title">
          <span>Why</span> CHOOSE us?
        </h2>
        <ul className="wcu-list">
          {ItemsArray}
        </ul>
      </div>
    </div>
  );
}
