const dataFaq = [
  {
    id: '1',
    number: '/01',
    question: 'How much does it cost to build a healthcare app?',
    answer: 'The cost of developing a medical application from scratch starts from $25,000 - $40,000. For this amount, you will receive an MVP version of a cross-platform application that will run on iOS, Android, and the web.'
  },
  {
    id: '2',
    number: '/02',
    question: 'How long does it take to develop a medical application for a hospital/clinic?',
    answer: 'The development of a medium complexity medical application takes 3-5 months. An app development timeline includes agreeing on the project specifications, creating a unique design, code development, testing and publishing the application on app stores.'
  },
  {
    id: '3',
    number: '/03',
    question: 'What is required to start the development process?',
    answer: 'To start a healthcare application development, you need to prepare: Specifications detailing what and how developers should do. Project design, which will make it clear what and how it should look.'
  },
  {
    id: '4',
    number: '/04',
    question: 'Will the application be HIPAA / GDRP compliant?',
    answer: 'Yes, applications that are developed in OSSystem meet all the privacy and data protection requirements of the country in which the application will be used. For the United States, this is CCPA and HIPAA compliant software, for the European Union, this is compliance with the GDPR rules.'
  },
];

export default dataFaq;