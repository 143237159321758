import React from 'react';
import './OurExperienceText.scss';

export default function OurExperienceText() {
  return (
    <div className="mads-our-experience-text-container">
      <div className="our-experience-text-wrapper">
        <div className="our-experience-text text--first">
          Our experience in medical applications development will help you get
          a truly in-demand application that your clients will use on a regular basis. We do not create applications for
          the sake of creating an application.
        </div>
        <div className="our-experience-text text--second">
          Our goal is to help your patients receive professional care
          and make your job easier and more efficient.
        </div>
      </div>
    </div>
  );
}
