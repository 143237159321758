import diagnosis from "../../../images/MedicalAppDevelopmentServices/HealthcareAreas/diagnosis.png";
import appointment from "../../../images/MedicalAppDevelopmentServices/HealthcareAreas/appointment.png";
import hospital from "../../../images/MedicalAppDevelopmentServices/HealthcareAreas/hospital.png";
import telehealth from "../../../images/MedicalAppDevelopmentServices/HealthcareAreas/telehealth.png";
import internet from "../../../images/MedicalAppDevelopmentServices/HealthcareAreas/internet.png";
import education from "../../../images/MedicalAppDevelopmentServices/HealthcareAreas/education.png";

const dataHealthcareAreas = [
  {
    id: '1',
    image: diagnosis,
    title: 'Medical diagnosis \n apps',
    content: 'Development of applications for medical diagnostics of patients based on artificial intelligence',
  },
  {
    id: '2',
    image: appointment,
    title: 'Medical appointment \n scheduling software',
    content: 'Development of HIPAA-compliant appointment scheduler for hospitals, clinics and medical offices',
  },
  {
    id: '3',
    image: hospital,
    title: 'Hospital management \n software',
    content: 'Development of cloud based hospital management solutions',
  },
  {
    id: '4',
    image: telehealth,
    title: 'Telehealth app \n development',
    content: 'Development of a telemedicine platforms for the provision of remote patient care services',
  },
  {
    id: '5',
    image: internet,
    title: 'Internet of medical \n things',
    content: 'Development of solutions for combining medical devices into a single ecosystem',
  },
  {
    id: '6',
    image: education,
    title: 'Medical education \n apps',
    content: 'Development of medical learning apps for students, patients or kids',
  },
];

export default dataHealthcareAreas;