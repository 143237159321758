import React from 'react';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataHealthcareAreas';
import './HealthcareAreas.scss';

export default function HealthcareAreas() {

  const arrayItems = data.map(item =>
    <div className="healthcare-areas-item" key={item.id}>
      <div className="healthcare-areas-item-image">
        <img src={item.image} alt=""/>
      </div>
      <h3 className="healthcare-areas-item-title">{item.title}</h3>
      <div className="healthcare-areas-item-content">{item.content}</div>
    </div>
  );

  return (
    <div className="healthcare-areas-container">
      <h2 className="healthcare-areas-title">
        <span>Healthcare areas</span> where we are <br/> good in app creation
      </h2>
      <div className="healthcare-areas-items">
        {arrayItems}
      </div>
    </div>
  );
}
