import imageIMlC from '../../../images/MedicalAppDevelopmentServices/MedicalAppCases/ingo-med-center.png';
import imageTDAfCP from '../../../images/MedicalAppDevelopmentServices/MedicalAppCases/trialjectory.png';
import imagePSF from '../../../images/MedicalAppDevelopmentServices/MedicalAppCases/patient-scheduling.png';

const dataMedicalAppCases = [
  {
    id: '1',
    image: imageIMlC,
    chips: [
      {
        title: 'android',
        link: 'https://play.google.com/store/apps/details?id=com.mcingo.mcingo'
      },
      {
        title: 'iOS',
        link: 'https://apps.apple.com/ua/app/mc-ingo/id1592321213'
      }
    ],
    title: 'Ingo Medical Center',
    subtitle: 'An application for clients of a network \n of multidisciplinary medical centers in Europe',
    contentVisible: 'With its help, the clients of the center can find the nearest clinic on the map, familiarize themselves with the list of services, apply for a course of treatment, make an appointment with a specialist, find out about the expertise of doctors, send and receive any medical documents (from an opinion to X-rays) and view the history of visits.',
    contentHidden: 'The application has been developed in compliance with international requirements for the security of personal data of users. A modular approach to the development of the application architecture was used, due to which it is constantly scalable, acquiring additional functionality.',
  },
  {
    id: '2',
    image: imageTDAfCP,
    chips: [
      {
        title: 'WEB',
        link: 'https://www.trialjectory.com'
      }
    ],
    title: 'TrialJectory - Diagnosis App for Cancer Patients',
    subtitle: 'A unique digital solution that determines the type of cancer using sophisticated diagnostic algorithms',
    contentVisible: 'Sequentially asking questions to the user, the application determines with high accuracy what type of cancer the patient has and what type of treatment he needs and where it can be obtained.',
    contentHidden: 'This app helps people find the right hospital to provide professional care. Thus, it saves the patient\'s precious time and gives him a chance to receive the necessary treatment in a timely manner.',
  },
  {
    id: '3',
    image: imagePSF,
    chips: [
      { title: 'WEB' },
      { title: 'android' },
      { title: 'iOS' }
    ],
    title: 'Patient Scheduling Software',
    subtitle: 'An UX project of a user-friendly app for doctors and patients of a medical clinic from New York',
    contentVisible: 'A project that allows doctors in a private hospital to effectively manage the schedule of patient admission, correspond with them, prescribe treatment, write prescriptions, see the medical history and refer to other specialists of the medical center.',
    contentHidden: 'On the other hand, patients can make an appointment with a doctor or cancel it, send a request for an extension of a prescription for drugs, watch a course of treatment, ask a doctor a question and receive a remote consultation on treatment from a specialist.',
  }
];

export default dataMedicalAppCases;