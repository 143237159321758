import React, {useState} from 'react';
import './MedicalAppCases.scss';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataMedicalAppCases';

export default function MedicalAppCases() {

  const [textHidden, setTextHidden] = useState([]);

  const handleClick = (id) => {
    setTextHidden([...textHidden, id]);
  }

  const arrayCases = data.map(item =>
    <div className="case" key={item.id}>
      <div className="case-image">
        <img src={item.image} alt=""/>
      </div>
      <div className="case-info">
        <div className="case-info-labels">
          {
            item.chips.map((chip, i) =>
              <div className={`case-info-label ${chip.title ? chip.title.toLowerCase() : ''}`} key={item.id + i}>
                {chip.link ? <a href={chip.link} target="_blank" >{chip.title}</a> : chip.title}
              </div>)
          }
        </div>
        <h3 className="case-info-title">{item.title}</h3>
        <div className="case-info-subtitle">{item.subtitle}</div>
        <div className="case-info-content">
          <div className="case-info-content-visible">
            {item.contentVisible + ' '}
            {
              !textHidden.includes(item.id) &&
              <span onClick={() => handleClick(item.id)}>Read More</span>
            }
          </div>

          <div className={`case-info-content-hidden ${textHidden.includes(item.id) ? 'show' : ''}`}>
            {item.contentHidden}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="medical-app-cases-container">
      <h2 className="medical-app-cases-title">
        <span>Medical</span> Application Cases
      </h2>
      <div className="medical-app-cases">
        {arrayCases}
      </div>
    </div>
  );
}
