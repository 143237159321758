import React from 'react';
import './EngagementModels.scss';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataEngagementModels';
import Card from "./Card";

export default function EngagementModels() {

  return (
    <div className="em-container">
      <div className="em-wrapper">
        <h2 className="em-title">Engagement Models</h2>
        <div className="em-cards">
          {
            data.map(card =>
              <Card
                key={card.id}
                title={card.title}
                description={card.description}
                textButton={card.textButton}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}
