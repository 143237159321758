import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/MedicalAppDevelopmentServices/Banner"
import OurResults from "../../components/MedicalAppDevelopmentServices/OurResults"
import OurExperienceText from "../../components/MedicalAppDevelopmentServices/OurExperienceText"
import OurAppsMentioned from "../../components/MedicalAppDevelopmentServices/OurAppsMentioned"
import HealthcareAreas from "../../components/MedicalAppDevelopmentServices/HealthcareAreas"
import MedicalAppCases from "../../components/MedicalAppDevelopmentServices/MedicalAppCases"
import LookingDevelopers from "../../components/MedicalAppDevelopmentServices/LookingDevelopers"
import ProcessOfDev from "../../components/MedicalAppDevelopmentServices/ProcessOfDev"
import EngagementModels from "../../components/MedicalAppDevelopmentServices/EngagementModels"
import WhyChoseUs from "../../components/MedicalAppDevelopmentServices/WhyChoseUs"
import Faq from "../../components/MedicalAppDevelopmentServices/Faq"
import HaveAnIdea from "../../components/MedicalAppDevelopmentServices/HaveAnIdea"
import { mainUrl } from "../../js/config"

export default function MedicalAppDevelopmentServices() {
  return (
    <div>
      <SEO
        title="Medical App Development Services for Hospitals and Clinics"
        description="Looking for medical app development services? You can create with us: hospital management software, doctor appointment applications, patient diagnostics solutions and more."
        canonical={`${mainUrl}/expertise/medical-app-development-services/`}
        ogImage={`${mainUrl}/med-app-dev.png`}
      />
      <Layout>
        <Banner />
        <OurResults />
        <OurExperienceText />
        <OurAppsMentioned />
        <HealthcareAreas />
        <MedicalAppCases />
        <LookingDevelopers />
        <ProcessOfDev />
        <EngagementModels />
        <WhyChoseUs />
        <Faq />
        <HaveAnIdea />
      </Layout>
    </div>
  )
}
