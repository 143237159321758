const dataCustomSelect = [
  {
    id: '1',
    text: 'Discuss the project',
  },
  {
    id: '2',
    text: 'Get a consultation',
  },
  {
    id: '3',
    text: 'Estimate a project',
  },
  {
    id: '4',
    text: 'Create a Product (Fixed Price)',
  },
  {
    id: '5',
    text: 'Build a Team (Dedicated Team)',
  },
  {
    id: '6',
    text: 'Get a Specialist (Time & Material)',
  },
];

export default dataCustomSelect;