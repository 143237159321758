import React from 'react';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataOurResults';
import './OurResults.scss';

export default function OurResults() {

  return (
    <div className="mads-our-results-container">
      {
        data.map(item =>
          <div className="our-results-item" key={item.id}>
            <div className="our-results-item-header">
              {item.count}
              <span>{item.name}</span>
            </div>
            <div className="our-results-item-text">
              {item.text}
            </div>
          </div>
        )
      }
    </div>
  );
}
