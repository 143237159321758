import React from 'react';
import './Faq.scss';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataFaq';
import FaqItem from "./FaqItem";

export default function Faq() {


  return (
    <div className="faq-container">
      <h2 className="faq-title">FAQ</h2>
      <ul className="faq-list">
        {
          data.map(item =>
            <FaqItem
              key={item.id}
              id={item.id}
              number={item.number}
              question={item.question}
              answer={item.answer}
            />
          )
        }
      </ul>
    </div>
  );
}
