import React from "react"
import "./HaveAnIdea.scss"
import FormFieldInternationalCopy from "../../CommonComponents/FormFieldInternationalCopy"
import dataForSelect from "../../../information/Expertise/MedicalAppDevelopmentServices/dataCustomSelect"

export default function HaveAnIdea() {
  const urlPage = typeof window !== "undefined" ? window.location.href : null

  return (
    <div className="hai-container">
      <div className="hai-wrapper">
        <h2 className="hai-title">
          Have a healthcare <br /> application idea?
        </h2>
        <div className="hai-subtitle">
          Let's estimate the project, tell us <br /> about your idea.
        </div>
        <div className="hai-form-container">
          <FormFieldInternationalCopy
            nameLabel={"Your Name *"}
            emailLabel={"E-mail *"}
            numberLabel={"Phone *"}
            messageLabel={"Message"}
            url={urlPage}
            form={9}
            dataForSelect={dataForSelect}
          />
        </div>
      </div>
    </div>
  )
}
