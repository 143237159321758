import React from 'react';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataOurAppsMentioned';
import './OurAppsMentioned.scss';

export default function OurAppsMentioned() {

  const dataForComponent = data.map(item =>
    <a
      key={item.id}
      className="mention-item"
      target="_blank"
      href={item.link}
      rel="noreferrer noopener nofollow"
    >
      <img className="mention-item-image" src={item.image} alt={'image'}/>
      <span className="mention-item-text">Read <br/> the article</span>
    </a>
  );

  return (
    <div className="mention-container">
      <h2 className="mention-title">
        <span>Our Healthcare</span> Apps are <br/>Mentioned by
      </h2>
      <div className="mention-items">
        {dataForComponent}
      </div>
    </div>
  );
}
