import React from "react"
import { Box, Modal } from "@material-ui/core"
import FormField from "../../CommonComponents/FormField"
import dataForSelect from "../../../information/Expertise/MedicalAppDevelopmentServices/dataCustomSelect"
import "./ModalPopUp.scss"
import FormFieldInternationalCopy from "../../CommonComponents/FormFieldInternationalCopy/FormFieldInternationalCopy"

export default function ModalPopUp({ openModal, handleClose, select }) {
  const urlPage = typeof window !== "undefined" ? window.location.href : null

  let selectValueDefault
  switch (select) {
    case "Create a Product":
      selectValueDefault = "Create a Product (Fixed Price)"
      break
    case "Build a Team":
      selectValueDefault = "Build a Team (Dedicated Team)"
      break
    case "Get a Specialist":
      selectValueDefault = "Get a Specialist (Time & Material)"
      break
    default:
      selectValueDefault = select
      break
  }

  return (
    <Modal
      disableAutoFocus={true}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-popup"
    >
      <Box className="modal-popup-body">
        <span className="modal-popup-close" onClick={handleClose}>
          &times;
        </span>
        <FormFieldInternationalCopy
          header={"Contact us"}
          nameLabel={"Your Name *"}
          emailLabel={"E-mail *"}
          numberLabel={"Phone *"}
          messageLabel={"Message"}
          url={urlPage}
          form={9}
          dataForSelect={dataForSelect}
          selectValueDefault={selectValueDefault}
        />
      </Box>
    </Modal>
  )
}
