import Forbes from "../../../images/MedicalAppDevelopmentServices/OurAppMentioned/Forbes.png";
import Time from "../../../images/MedicalAppDevelopmentServices/OurAppMentioned/Time.png";
import NewYorkTimes from "../../../images/MedicalAppDevelopmentServices/OurAppMentioned/The-New-York-Times-Amblem.png";

const dataOurAppsMentioned = [
  {
    id: '1',
    image: Forbes,
    link: 'https://www.forbes.com/sites/debgordon/2021/08/17/startup-helps-cancer-patients-find-clinical-trials-offering-new-hope-and-more-options/?sh=4a67d7ef6bab',
  },
  {
    id: '2',
    image: Time,
    link: 'https://time.com/collection/best-inventions-2020/5911318/trialjectory/',
  },
  {
    id: '3',
    image: NewYorkTimes,
    link: 'https://www.nytimes.com/2020/04/20/health/treatment-delays-coronavirus.html',
  },
];

export default dataOurAppsMentioned;