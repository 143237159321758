const dataOurResults = [
  {
    id: '1',
    count: '15',
    name: 'Years',
    text: 'in web & mobile app development'
  },
  {
    id: '2',
    count: '40+',
    name: 'Developers',
    text: 'experienced in software engineering'
  },
  {
    id: '3',
    count: '120+',
    name: 'Clients',
    text: 'satisfied with the project results'
  },
  {
    id: '4',
    count: '300+',
    name: 'Projects',
    text: 'successfully completed'
  },
]
export default dataOurResults;

