import React, {useState} from 'react';
import './ProcessOfDev.scss';
import data from '../../../information/Expertise/MedicalAppDevelopmentServices/dataProcessOfDev';

export default function ProcessOfDev() {

  const [textHidden, setTextHidden] = useState([]);

  const handleClick = (id) => {
    setTextHidden([...textHidden, id]);
  }

  const arrayItems = data.map(item =>
    <div className="pd-list-item" key={item.id}>
      <div className="pd-item-image">
        <img src={item.image} alt=""/>
      </div>
      <div className="pd-item-content">
        <h3 className="pd-item-title">{item.title}</h3>
        <div className="pd-item-text">
          <div className="pd-item-text-visible">
            {item.textVisible + ' '}
            {
              !textHidden.includes(item.id) &&
              <span onClick={() => handleClick(item.id)}>Read More</span>
            }
          </div>
          <div className={`pd-item-text-hidden ${textHidden.includes(item.id) ? 'show' : ''}`}>
            {item.textHidden}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="pd-container">
      <div className="pd-wrapper">
        <h2 className="pd-title">
          <span>Medical</span> App Development Process
        </h2>
        <div className="pd-list">
          {arrayItems}
        </div>
      </div>
    </div>
  );
}
