import React, {useState} from "react"
import "./Banner.scss"
import ModalPopUp from "../ModalPopUp";

export default function Banner() {

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <div className="mads-banner-container">
        <div className="mads-banner-wrapper">
          <h1 className="mads-banner-title">Medical App Development <br/>Services</h1>
          <div className="mads-banner-subtitle">for hospitals, clinics, doctors <br/>and patients</div>
          <button
            className="mads-banner-button"
            onClick={handleOpen}
          >
            discuss the project
          </button>
        </div>
      </div>
      {
        openModal &&
        <ModalPopUp
          openModal={openModal}
          handleClose={handleClose}
          select={'Discuss the project'}
        />
      }
    </>
  )
}
